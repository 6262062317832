import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Result } from 'src/app/shared/models/result';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UfvService {

  constructor(
    private http: HttpClient
    
  ) { }

  findAll(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/ufvs/gestiones`);
  }

  findById(id: number): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/ufvs/gestiones/${id}`);
  }

  persist(dto: any): Observable<Result> {
    return this.http.post<Result>(`${environment.apiSecurityUrl}api/v1/ufvs/gestiones`, dto)
  }

  update(id: number, dto: any): Observable<Result> {
    return this.http.put<Result>(`${environment.apiSecurityUrl}api/v1/ufvs/gestiones/${id}`, dto);
  }

  delete(id: number): Observable<Result> {
    return this.http.delete<Result>(`${environment.apiSecurityUrl}api/v1/ufvs/gestiones/${id}`);
  }
}
